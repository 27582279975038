import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetAuthCustomer, GetCustomer } from './customer.actions';
import { ICustomer, IRole } from '../model/customer.model';
import { AuthService } from 'src/modules/authentication/services/auth/auth.service';
import { catchError, tap } from 'rxjs/operators';
import { produce } from 'immer';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerService } from '../services/customer.service';

export class CustomerStateModel {
  public customer: ICustomer;
  public authCustomer: any;
  public roles: IRole[];
  
  isCustomerLoading: boolean;

}

const defaults = {
  customer: null,
  authCustomer: null,
  roles: [],

  isCustomerLoading: true,
};

@State<CustomerStateModel>({
  name: 'customer',
  defaults,
})

@Injectable()
export class CustomerState {
  constructor( private authService: AuthService, private customerService: CustomerService, private toastr: ToastrService ) {}

  @Selector()
  static getCustomerObject(state: CustomerStateModel) {
    return state.customer;
  }

  // @Selector()
  // static getCustomerRoles(state: CustomerStateModel) {
  //   return state.roles;
  // }

  @Selector()
  static isCustomerLoading(state: CustomerStateModel) {
    return state.isCustomerLoading;
  }

  @Action(GetCustomer, {cancelUncompleted: true})
  getCustomer(ctx: StateContext<CustomerStateModel>) {
    return this.customerService.getCustomerSelf().pipe(tap((customer: ICustomer) => {
      
      const state = produce(ctx.getState(), draft => {
        draft.customer = customer;
        draft.isCustomerLoading = false;
      });
      
      ctx.setState(state);

      // ctx.dispatch(new GetCustomerRoles());

    }), catchError((error: HttpErrorResponse): Observable<any> => {
        setTimeout(function(){ 
            this.toastr.error('Error getting customer data. Please contact reseller.', 'Error', {disableTimeOut: true});
          }, 5000);
          // setInterval(function(){ this.authService.logout(); }, 3000);
          return throwError(() => new HttpErrorResponse(error));
        }
    ));
  }


  @Action(GetAuthCustomer, {cancelUncompleted: true})
  getAuthCustomer(ctx: StateContext<CustomerStateModel>) {
    return this.authService.getCustomerProfile().pipe(tap((result) => {
      const state = produce(ctx.getState(), draft => {
      });
      
      ctx.setState(state);
    }), catchError((error: HttpErrorResponse): Observable<any> => {
        setTimeout(function(){ 
            this.toastr.error('Error getting customer data. Please contact reseller.', 'Error', {disableTimeOut: true});
          }, 5000);
          // setInterval(function(){ this.authService.logout(); }, 3000);
          return throwError(() => new HttpErrorResponse(error));
        }
    ));
  }


  // @Action(GetCustomerRoles, {cancelUncompleted: true})
  // getCustomerRoles(ctx: StateContext<CustomerStateModel>) {
  // return this.customerService.getCustomerRoles().pipe(tap((result) => {
  //   const state = produce(ctx.getState(), draft => {
  //     draft.roles = result;
  //   });
    
  //   ctx.setState(state);

  //   }), catchError((error: HttpErrorResponse): Observable<any> => {
  //     this.toastr.error('Error getting customer roles.', 'Error');
  //     return throwError(error);
  //   }));
  // }
}
